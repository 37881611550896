.wrapper {
  margin-top: 35px;

  width: 100%;
  height: 40%;
  padding: 0 30px;

  display: flex;
  justify-content: space-between;

  position: relative;

  label {
    width: 100%;
    text-align: center;
  }

  > div {
    width: 50%;
    padding:0 10px;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;

    select {
      margin-top: 10px;
      height: 30px;
      width: 75%;
      text-align: center;
    }
    option {
      font-size: 1rem;
    }

  }


  .right {
    display: flex;
    align-items: center;
    flex-direction: column;

    input {
      width: 90%;

      border-radius: 4px;
      height: 35px;
      margin: 5px 0;
      background-color: #404040;
      color: white;
    }

    > div {
      display: flex;
      width: 90%;
      justify-content: space-evenly;


      button {
        width: 42%;
        border-radius: 6px;
        height: 35px;
        margin: 2px 5px;
      }
    }

    >button {
      width: 30%;
      border-radius: 6px;
      height: 35px;
      //margin: 2px 5px;
      position: absolute;
      bottom: 30px;
      right: 55px;
    }
  }
}

@media (max-width: 426px) {
  .wrapper {
    flex-direction: column;
    align-items: center;

      > div {
        width: 80%;
      }

    .right {

      > button {
        margin-top: 20px;
        position: static;
      }
    }
  }
}