.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: calc(100vh - 195px);

  .clientArea {
    height: 30%;
  }

  .clientAreaTitle {
    color: #fff;
    text-align: center;
    font-size: 44px;
    font-weight: 700;
  }

  .loginContainer {
    padding-top: 20px;

    height: 70%;
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
      font-size: 14px;
      color: #727272;
      line-height: 28px;
      font-weight: 400;
      margin-bottom: 10px;
    }

    label {
      margin-bottom: 5px;
      color: #979797;
      font-size: 16px;
    }

    input {
      border-radius: 4px;
      border: 1px solid #BBBBBB;
      height: 36px;
      padding: 5px 10px;
      width: 400px;
    }

    button {
      background-color: #00b894;
      border-radius: 8px;

      margin-top: 10px;

      height: 35px;
      width: 200px;
    }
  }
}

@media (max-width: 426px) {
  .clientArea {
    display: none;
  }

}