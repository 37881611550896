.wrapper {
    background-color: #1F4E79;

    margin-top: 20px;

    > div {
        display: flex;
        flex-wrap: wrap;

        margin: 5px;


        .chassiInfo {
            padding: 4px;
            margin: 5px;

            span {
                padding: 4px;
            }

            span:last-child {
                background-color: #404040;

            }
        }
    }

    .infoBlock {
        height: 40px;
        display: flex;
        align-items: center;

        .entrada {
            padding: 2px;
            display: inline-block;

            width: 200px;
            text-align: left;
            line-break: anywhere;
        }

        > div {

            .infoSpan {
                text-align: center;
                display: inline-block;
                width: 130px;
                color: #1F4E79;
                font-size: 16px;
                padding: 4px;
                margin: 2px;
                background-color: #A9D18E;
                border-radius: 2px;
            }

            .redFlag {
                text-align: center;
                display: inline-block;
                width: 130px;
                color: #F2F2F2;
                font-size: 16px;
                padding: 4px;
                margin: 2px;
                background-color: #C00000;
                border-radius: 2px;

            }
        }
    }
}

.buttonContainer {
    align-self: flex-end;
    margin-top: 5px;
    padding-right: 2rem;


    button {
        background-color: #D9D9D9;
        width: 160px;
        height: 35px;
        border-radius: 3px;
        margin: 3px;
        cursor: pointer;
    }
}


@media (max-width: 426px) {
    .wrapper {
        width: 100%;
        padding: 0;
        margin: 0;

        text-align: center;

        > div {
            flex-direction: column;
            border-bottom: #00b894 1px solid;
            margin-bottom: 10px;
            //flex-wrap: nowrap;
        }

        .infoBlock {
            flex-direction: column;
            height: 100px;

            .entrada {
                display: block;
                height: 20px;
                //margin-bottom: 5px;
            }

            > div {
                height: 45px;
                //
                .infoSpan {
                    //text-align: center;
                    ////display: inline-block;
                    //width: 60px;
                    //color: #1F4E79;
                    //font-size: 16px;
                    //background-color: #A9D18E;
                    //border-radius: 2px;
                    //height: 100%;
                }

                //.redFlag {
                //    text-align: center;
                //    //display: inline-block;
                //    width: 60px;
                //    color: #F2F2F2;
                //    font-size: 16px;
                //    background-color: #C00000;
                //    border-radius: 2px;
                //    height: 100%;
                //}
            }
        }
    }


}