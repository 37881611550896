.container {
  background-color: #1F4E79;
  color: white;


  margin: 0 auto;
  width: 800px;
  height: 600px;

  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;

  p:first-child {
    padding-top: 15px;
  }

  hr {
    margin: 10px 0;
    height: 1px;
    width: 100%;
    color: #5391C9;
  }
}

@media (max-width: 426px) {
  .container {
    max-width: 100vw;
  }

}