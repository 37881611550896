.pageContainer {
  background-color: red;

  width: 800px;
  height: 400px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;

  form {

    button {
      width: 200px;
      height: 45px;
    }

  }
}