.container {
  .headerTop {
    position: relative;
    background: rgba(44, 44, 44, 0.5);

    color: white;

    top: 0;
    left: 0;
    width: 100vw;
    height: 45px;

    padding: 12px 0;

    .topContainer {
      width: 90%;
      margin: 0 auto;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .socialMedia {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      text-align: left;
      display: flex;
      margin-left: 50px;

      a {
        outline: medium none;
        transition: 0.3s;
        line-height: 1.5;
        text-align: left;
      }

      i {
        font-size: 15px;
        color: #fff;
        margin-right: 12px;
        text-decoration: none;
        background-color: transparent;
        cursor: pointer;
        outline: medium none;
        transition: 0.3s;
      }
    }

    .contactList {
      ul {
        display: flex;
      }
    }

    i:last-child {
      margin-left: 10px;
    }
  }

  .headerBottom {

    background-color: transparent;
    color: white;

    width: 100vw;
    height: 150px;

    .bottomContainer {
      width: 90%;
      margin: 0 auto;
      padding-top: 2rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid #494C4F;


      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        a {
          color: #1F1F1F;

          .oficialLogo {
            max-width: 145px;
            vertical-align: middle;
            border-style: none;
          }
        }
      }

      .headerMainMenu {
        nav {

        }

        ul {
          display: flex;
        }

        li {
          margin: 6px;
        }
      }
    }
  }

  //border-bottom: 1px solid black;
}